import React, {Component} from "react";
import {

    deleteFile,
    getAllProductsForList,
    getCustomer, getFileAttachments,
    getProduct, getVisitation,
    getVisitations, updateVisitation,
} from "../util/APIUtils";
import {Button, Form, Image, Input, Modal, notification, Select, Table, } from "antd";
// import 'antd/dist/antd.less';
import {CloseOutlined, EuroOutlined, ExclamationCircleOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import './CustomerCard.css';
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import Upload from 'rc-upload';
import NewCustomer from "./NewCustomer";
import {API_BASE_URL_PHOTO} from "../constants";
const { Option } = Select;


class CustomerCard extends Component{
    constructor(props) {
        super(props);
        this.state={
            searchText: '',
            searchedColumn: '',
            isLoading:false,
            customerId: props.customer != null ? props.customer : this.props.match?.params?.customerId,
            visitations:null,
            customer:{},
            visitationModal:false,
            visitation:null,
            productUsages:[],
            productList:null,
            productUsageModal: false,
            comment:null,
            product:null,
            fileAttachments:[],
            visitationModalView:false,
            customerEditModal:false,
            phoneChooseModal:false,
        }

        this.loadCustomer = this.loadCustomer.bind(this);
        this.loadVisitations = this.loadVisitations.bind(this);
        this.handleConditionChange = this.handleConditionChange.bind(this);
        this.handleProblemsChange = this.handleProblemsChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleExpenseChange = this.handleExpenseChange.bind(this);

    }

    componentDidMount() {
        this.loadCustomer(this.state.customerId);
        this.loadVisitations(this.state.customerId);
    }

    loadCustomer(customerId){
        let promise;
        promise = getCustomer(customerId);

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    customer: response ,
                    isLoading: false,
                });

            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    loadVisitations(customerId){
        let promise;
        promise = getVisitations(customerId);

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    visitations: response ,
                    isLoading: false,
                });

            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    // icon={<SearchOutlined />}
                    icon={"search"}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        confirm({ closeDropdown: false });
                        this.setState({
                            searchText: selectedKeys[0],
                            searchedColumn: dataIndex,
                        });
                    }}
                >
                    Filter
                </Button>

            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };


    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleCancel=()=>{
        this.setState({
            visitationModal:false,
            visitationModalView:false,
            customerEditModal:false,
            phoneChooseModal:false,
        })
    }

    handleSubmit=()=>{
        const visitation = {
            ...this.state.visitation,
            price:this.state.visitation?.price?.value,
            expense:this.state.visitation?.expense?.value,
            productUsages: this.state.productUsages
        }

        updateVisitation(visitation)
            .then(response => {
                this.loadVisitations(this.state.customerId);
                this.setState({
                    visitationModal:false,
                })
                notification.success({
                    message: 'ediMiss App',
                    description: response.message
                });
            }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
            } else {
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            }
        });
    }

    handleConditionChange(event) {
        const value = event.target.value;
        this.setState({
            visitation:{
                ...this.state.visitation,
                condition:value,
            }
        });
    }

    handleProblemsChange(event) {
        const value = event.target.value;
        this.setState({
            visitation:{
                ...this.state.visitation,
                problems:value,
            }
        });
    }

    handlePriceChange(event) {
        const value = event.target.value;
        this.setState({
            visitation:{
                ...this.state.visitation,
                price:{
                    value: value,
                    ...this.validatePriceInput(value)
                },

            }
        });
    }

    handleExpenseChange(event) {
        const value = event.target.value;
        this.setState({
            visitation:{
                ...this.state.visitation,
                expense:{
                    value: value,
                    ...this.validateExpenseInput(value)
                }
            }
        });
    }

    handleVisitationModalView=(event)=>{
        this.setState({
            visitationModalView:true,
            visitation:event,
        });
    }

    handleVisitationModalOpen=(event)=>{
        let promise;
        promise = getFileAttachments(event.id);

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                getVisitation(event.id).then(response2=>{
                    this.setState({
                        fileAttachments: response,
                        visitation:response2,
                        productUsages:response2.productUsages,
                        visitationModal:true,
                        isLoading: false,
                        visitationModalView:false,
                    })
                })
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    handleCancelUsage=()=>{
        this.setState({
            productUsageModal:false,
            comment:null,
            product:null,
        })
    }

    handleProductUsageAdd=()=>{
        const productUsage={
            product: {id:this.state.product.id, name:this.state.product.name},
            comment: this.state.comment,
        }
        const productUsages = this.state.productUsages;
        productUsages.push(productUsage);
        this.setState({
            productUsages: productUsages,
            productUsageModal:false,
            product: null,
            comment: null,
        })
    }


    handleCommentChange=(event)=>{
        this.setState({
            comment:event.target.value,
        })
    }

    handleProductSelectChange=(value)=>{
        let promise;
        promise = getProduct(parseInt(value));

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    product: response,
                    isLoading: false,
                });
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    handleProductUsageModalOpen=()=>{
        let promise;
        promise = getAllProductsForList();

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    productList: response,
                    isLoading: false,
                    productUsageModal:true,
                    product:null,
                });
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    handleProductUsageRemove=(index)=>{
        const productUsages = this.state.productUsages.slice();
        this.setState({
            productUsages:[...productUsages.slice(0,index), ...productUsages.slice(index+1)],
        })
    }

    handleFileRemove=(fileId)=>{
        const fileData = {
            id: parseInt(fileId)
        };

        deleteFile(fileData)
            .then(response => {
                getFileAttachments(this.state.visitation.id)
                    .then(response=>{
                        this.setState({
                            fileAttachments:response
                        })
                        notification.success({
                            message: 'ediMiss App',
                            description: response.message,
                        });
                    })

            }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
            } else {
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            }
        });
    }



    imagesComponent=(items)=>{

        return(
            <div style={{border:"1px solid #efefef", padding:"10px", marginTop:"20px"}}>
                {items.map(item=>(
                    <div className={"imageBox"}>
                            <Image
                                width={100}
                                src={item.url}
                            />
                        <div style={{float:"right", fontSize:"35px", color:"#a40000", cursor:"pointer"}}>
                            <CloseOutlined onClick={()=>this.handleFileRemove(item.id)}/>
                        </div>
                    </div>
                ))}
            </div>
        );
    }



    onSuccessHandle=(event)=>{
        this.setState({
            fileAttachments:[...this.state.fileAttachments, event]
        })
    }

    handleCustomerEditModalOpen=()=>{
        this.setState({
            customerEditModal:true,
        })
    }

    handlePhoneModalOpen=()=>{
        this.setState({
            phoneChooseModal:true,
        })
    }

    validatePriceInput=(price)=>{
        if(price.length === 0) {
            return {
                validateStatus: 'error',
                errorMsg: 'Please enter price!'
            }
        }else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }
    validateExpenseInput=(expense)=>{
        if(expense.length === 0) {
            return {
                validateStatus: 'error',
                errorMsg: 'Please enter expense!'
            }
        }else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }

    render() {

        const columns = [
            {
                title: 'Date',
                dataIndex: 'visitationDate',
                key: 'visitationDate',
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                ...this.getColumnSearchProps('title'),
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                // width: '30%',
                ...this.getColumnSearchProps('price'),
            },
        ];

        const children = [];
        this.state.productList?.forEach(product => {
                if(this.state.productUsages.length!==0){
                    const productU=this.state.productUsages;
                    if(productU.some(item=> item.product.id === product.id)){
                        return;
                    }else{
                        children.push(<Option key={product.id}><div style={{display:"inline-block"}}>{product.name} <p>{product.supplier}</p></div> <div style={{float:"right",display:"inline-block"}}><Image
                            width={40}
                            style={{borderRadius:"20px"}}
                            src={product.productImage?.url!=null?product.productImage.url:"https://basanaviciausprogimnazija.lt/wp-content/uploads/2018/04/no-user-image.gif"}
                        /></div></Option>);
                    }
                }else {
                    children.push(<Option key={product.id}><div style={{display:"inline-block"}}>{product.name} <p>{product.supplier}</p></div> <div style={{float:"right",display:"inline-block"}}><Image
                        width={40}
                        style={{borderRadius:"20px"}}
                        src={product.productImage?.url!=null?product.productImage.url:"https://basanaviciausprogimnazija.lt/wp-content/uploads/2018/04/no-user-image.gif"}
                    /></div></Option>);
                }
        })





        return(
            <div>
                <div style={{marginTop:"20px"}}>
                    <div style={{display: "inline-block", width: "100%"}}>
                        <h2
                            style={{display: "inline-block"}}
                            className="site-page-header"
                        >{this.state.customer?.fullName}</h2>
                        {this.state.customer?.customerPicture!=null?(
                            <div style={{display: "inline-block" }}>
                                <Image
                                    className={"customerListImage"}
                                    src={this.state.customer?.customerPicture?.url}
                                />
                            </div>
                        ):null}
                        <div style={{display: "inline-block", float: "right", padding: "20px"}}>
                            <Button
                                className={"buttonAdd"}
                                size="large"
                            onClick={this.handleCustomerEditModalOpen}>
                                Edit
                            </Button>
                        </div>
                    </div>
                        <div className={"customerBasicInfoComponent"}>

                        {this.state.customer?.phoneNumber !== null ? (
                            <div className={"customerBasicInfoFields"}>Phone Number:  <strong><a onClick={this.handlePhoneModalOpen}>{this.state.customer?.phoneNumber}</a></strong></div>
                        ):null}
                        {this.state.customer?.email !== null ? (
                            <div className={"customerBasicInfoFields"}>Email:  <strong><a href={"mailto:"+this.state.customer?.email}>{this.state.customer?.email}</a></strong></div>
                        ):null}
                        {this.state.customer?.customerCustomFields?.map(item=>(
                            <div key={item.id} className={"customerBasicInfoFields"}>{item.name}: <strong>{item.comment}</strong></div>))}


                    </div>
                    <div className={"customerIllnessInfoComponent"}>
                        <div className={"customerBasicInfoFields"}>Illnesses:</div>
                        {this.state.customer?.illnesses?.map((item, index)=>(<div className={"customerBasicInfoFields"}>{index+1}. <strong>{item.name}</strong></div>))}
                    </div>
                </div>

                <Table columns={columns} dataSource={this.state.visitations} rowKey={"id"} onRow={(r) => ({
                    onClick: ()=>{this.handleVisitationModalView(r)},
                })}/>

                <Modal title="Visitation" visible={this.state.visitationModal} onCancel={this.handleCancel} footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>,
                    <Button  onClick={this.handleSubmit}  type="primary" htmlType="submit" >
                        Update
                    </Button>
                ]}>
                    <Form  className="create-poll-form" onSubmit={this.handleSubmit} >
                        <FormItem className="poll-form-row">
                            <Upload
                                action={API_BASE_URL_PHOTO+'/file/upload/'+this.state.visitation?.id}
                                onSuccess={this.onSuccessHandle}
                                multiple={true}
                            ><Button className={"uploadButton"}>Upload Images</Button></Upload>
                            {this.state.fileAttachments?.length !==0 ? this.imagesComponent(this.state.fileAttachments):null}
                        </FormItem>
                        <FormItem  className="poll-form-row" >
                            <TextArea
                                placeholder={"Condition"}
                                value={this.state.visitation?.condition}
                                style = {{ fontSize: '16px' }}
                                autoSize={{ minRows: 3, maxRows: 6 }}
                                size="large"
                                onChange = {this.handleConditionChange}
                            />
                        </FormItem>
                        <FormItem  className="poll-form-row" >
                            <TextArea
                                placeholder={"Problems"}
                                value={this.state.visitation?.problems}
                                style = {{ fontSize: '16px' }}
                                autoSize={{ minRows: 3, maxRows: 6 }}
                                size="large"
                                onChange = {this.handleProblemsChange}
                            />
                        </FormItem>
                        <FormItem  className="poll-form-row" validateStatus={this.state.visitation?.price?.validateStatus}
                                   help={this.state.visitation?.price?.errorMsg} >
                            <Input
                                placeholder={"Price"}
                                name={"price"}
                                value = {this.state.visitation?.price?.value === null?undefined:this.state.visitation?.price?.value}
                                className={this.state.visitation?.price?.validateStatus !== 'success' ? "optional-choice": null}
                                style = {{ fontSize: '16px' }}
                                size="large"
                                type={"number"}
                                onChange = {this.handlePriceChange}
                            />
                            {this.state.visitation?.price?.validateStatus !=='success' ? (
                                <ExclamationCircleOutlined className={"dynamic-delete-button"} style={{color:'red'}}/>
                            ): null}
                        </FormItem>
                        <FormItem  className="poll-form-row" validateStatus={this.state.visitation?.expense?.validateStatus}
                                   help={this.state.visitation?.expense?.errorMsg}>
                            <Input
                                placeholder={"Expense"}
                                name={"expense"}
                                value = {this.state.visitation?.expense?.value === null?undefined:this.state.visitation?.expense?.value}
                                className={this.state.visitation?.expense?.validateStatus !== 'success' ? "optional-choice": null}
                                style = {{ fontSize: '16px' }}
                                size="large"
                                type={"number"}
                                onChange = {this.handleExpenseChange}
                            />
                            {this.state.visitation?.expense?.validateStatus !=='success' ? (
                                <ExclamationCircleOutlined className={"dynamic-delete-button"} style={{color:'red'}}/>
                            ): null}
                        </FormItem>

                        {this.state.productUsages?.map((item, index)=>(<ProductUsageComponent productUsage={item} productUsageNumber={index} handleRemove={this.handleProductUsageRemove}/>))}

                        <FormItem className="poll-form-row">
                            <Button type="dashed" onClick={this.handleProductUsageModalOpen} >
                                <PlusOutlined /> Add product usage
                            </Button>
                        </FormItem>

                    </Form>
                </Modal>
                <Modal title="Product Usage" visible={this.state.productUsageModal}  onCancel={this.handleCancelUsage} footer={[
                    <Button key="back" onClick={this.handleCancelUsage}>
                        Cancel
                    </Button>,
                    <Button form={"programUsageAddForm"} type="primary" htmlType="submit" >
                       Add
                    </Button>,
                ]}>
                    <div>
                        <Form id={"programUsageAddForm"} onFinish={this.handleProductUsageAdd} className="create-poll-form" >
                            <FormItem  className="poll-form-row" >

                                <Select
                                    size="large"
                                    placeholder="Please select"
                                    onChange={this.handleProductSelectChange}
                                    showSearch
                                    value={this.state.product !=null?this.state.product.name:undefined}
                                    filterOption={(input, option) =>
                                    {
                                        const values = option.props.children[0].props.children[0] + (option.props.children[0].props.children[2].props.children!=null? (" "+option.props.children[0].props.children[2].props.children): "");
                                        return values.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                    }
                                >
                                    {children}
                                </Select>
                            </FormItem>
                            <FormItem  className="poll-form-row">
                                <Input
                                    placeholder="Enter Comment"
                                    style = {{ fontSize: '16px' }}
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    size="large"
                                    value = {this.state.comment}
                                    onChange = {this.handleCommentChange} />
                            </FormItem>

                        </Form>
                    </div>

                </Modal>
                <Modal title="Visitation View" visible={this.state.visitationModalView}  onCancel={this.handleCancel} footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>,
                    <Button key={"primary"} onClick={()=>this.handleVisitationModalOpen(this.state.visitation)}>
                        Edit
                    </Button>,
                ]}>
                    <div>
                        <div>
                            <div style={{width:"100%", textAlign:"center"}}>
                                <h2 style={{background:"#fccbff", borderRadius:"10px", color:"#fff"}}>{this.state.visitation?.visitationDate}</h2>
                            </div>
                            <div className={"visitationViewFields"}>
                                <strong>Condition: </strong> <em>{this.state.visitation?.condition}</em>
                            </div>
                            <div className={"visitationViewFields"}>
                                <strong>Problems:</strong> <em>{this.state.visitation?.problems}</em>
                            </div>
                            <div className={"visitationViewFields"}>
                                <strong>Price:</strong> <em>{this.state.visitation?.price} <EuroOutlined /></em>
                            </div>
                            <div className={"visitationViewFields"}>
                                <strong>Expense:</strong> <em>{this.state.visitation?.expense} <EuroOutlined /></em>
                            </div>
                            <div className={"visitationViewFields"}>
                                <strong>Used products:</strong> {this.state.visitation?.productUsages?.map(item=>(
                                <div style={{display:"inline-block",width:"100%"}}>
                                    <div style={{display:"inline-block"}}>
                                        <em >{item.product.name} ({item.product.supplier})</em>
                                    </div>
                                    <div style={{display:"inline-block", float:"right"}}>
                                    {item.product.productImage!=null?(
                                            <Image
                                                width={100}
                                                style={{ borderRadius:"10px"}}
                                                src={item.product?.productImage.url}
                                            />
                                    ):null}
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                        <div style={{border:"1px solid", borderColor:"#d7d7d7", borderRadius:"10px", padding:"20px", marginTop:"20px"}}>
                            {this.state.visitation?.fileAttachments?.map(item=>(
                                <div style={{display:"inline-block", margin:"5px"}}>
                                    <Image
                                        width={100}
                                        src={item.url}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal>
                <Modal title="Edit Customer Information" visible={this.state.customerEditModal}  onCancel={this.handleCancel} footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>
                ]}>
                    <NewCustomer customer={this.state.customer} handleCancel={this.handleCancel} reloadCustomer={this.loadCustomer}/>

                </Modal>
                <Modal visible={this.state.phoneChooseModal}  onCancel={this.handleCancel} footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>
                ]}>
                    <Button type={"primary"} style={{width:"100%", marginTop:"40px"}} href={"sms:"+this.state.customer?.phoneNumber}>Send SMS</Button>
                    <Button type={"primary"} style={{width:"100%", marginTop:"40px"}} href={"tel:"+this.state.customer?.phoneNumber}>Call</Button>

                </Modal>
            </div>
        );
    }
}

function ProductUsageComponent(props){
    return(<div className={"productUsageComponentBlock"}>
        <div className={"usageProgram"}>{props.productUsage?.product.name}</div>
        <div className={"usageImage"}>
            <Image
                width={40}
                style={{borderRadius:"20px"}}
                src={props.productUsage?.product?.productImage?.url!=null?props.productUsage?.product?.productImage.url:"https://basanaviciausprogimnazija.lt/wp-content/uploads/2018/04/no-user-image.gif"}
            />
        </div>
        <div className={"usageComment"}>{props.productUsage.comment}</div>
        <div className={"usageRemove"}><CloseOutlined onClick={()=>props.handleRemove(props.productUsageNumber)}/></div>
    </div>);
}

export default CustomerCard;
