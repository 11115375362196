import React, {Component} from 'react'

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { confirmAlert } from 'react-confirm-alert';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {Button, Form, Image, Input, Modal, notification, Popconfirm, Spin, TimePicker} from "antd";
// import 'antd/dist/antd.less';

import {
    createEvent,
    createVisitationFromEvent,
    deleteEvent, existVisitationByEvent,
    getAllCustomersList,
    getAllEvents,
    updateEvent
} from "../util/APIUtils";
import { Select } from 'antd';
import FormItem from "antd/es/form/FormItem";
import "./CalendarSch.css";
import {
    withRouter
} from 'react-router-dom';
import CustomerCard from "../customer/CustomerCard";
import {ExclamationCircleOutlined} from "@ant-design/icons";

require('moment/locale/lt');
const { Option } = Select;

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);




class CalendarSch extends Component {
    constructor(props) {
        super(props);
        this.state={
            events: [],
            eventModal:false,
            isLoading: false,
            customers:null,
            eventClickModal: false,
            eventCustomerModal:false,
            eventEditModal: false,
            event:{start:null, end:null, title:null, customer:null},
            existVisitationByEvent:false,
        }


        this.handleCancel = this.handleCancel.bind(this);
        this.openEventCreateModal = this.openEventCreateModal.bind(this);
        this.loadCustomers = this.loadCustomers.bind(this);
        this.onChangeSelection = this.onChangeSelection.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.loadEvents = this.loadEvents.bind(this);
        this.handleCustomerModalOpen = this.handleCustomerModalOpen.bind(this);
        this.handleDeleteEvent =this.handleDeleteEvent.bind(this);
    }



    loadEvents(){
        let promise;
        promise = getAllEvents();

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {

                const responseData=response;

                for(let i = 0; i<responseData.length; i++){

                    responseData[i].start = new Date(responseData[i].start);
                    responseData[i].end = new Date(responseData[i].end);
                }

                this.setState({
                    events: responseData,
                    isLoading: false
                });
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    loadCustomers(){
        let promise;
        promise = getAllCustomersList();

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    customers: response,
                    isLoading: false
                });
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    componentDidMount() {
        this.loadCustomers();
        this.loadEvents();
    }

    onEventResize = (data) => {
        const { start, end } = data;

        this.setState((state) => {
            state.events[0].start = start;
            state.events[0].end = end;
            return { events: [...state.events] };
        });
    };

    // resetEventValues=()=>{
    //     console.log("resetina")
    //     this.setState({
    //         customer:null,
    //         title:null,
    //         startDate:null,
    //         endDate:null,
    //         event:null,
    //     })
    // }

    onEventDrop = (data) => {
        const startDate = new Date(data.start).toLocaleString('lt-LT');
        const endDate = new Date(data.end).toLocaleString('lt-LT');
        const eventData={
            id: data.event.id,
            start:startDate,
            end: endDate,
            customer:data.event.customer.id,
            title:data.event.title,
        }

        this.setState({
            isLoading: true
        });

        updateEvent(eventData)
            .then(response => {
                this.props.history.push("/calendar");
                this.handleCancel();
                this.loadEvents();
            }).catch(error => {
            if(error.status === 401) {
                this.setState({
                    isLoading: false
                });
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');

            } else {
                this.setState({
                    isLoading: false
                });
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });

            }
        });

    };

    openEventCreateModal=(slotinfo)=>{

        // this.setState({
        //     startDate: slotinfo.start,
        //     endDate:slotinfo.end,
        //     customer:null,
        //     title:null,
        //     eventModal:true,
        // })

        this.setState({
            event:{
                ...this.state.event,
                start: slotinfo.start,
                end:slotinfo.end,
                customer:null,
                title:null,
            },
            eventModal:true,
        })

    }


    handleCancel = () => {
        this.setState({
            eventModal:false,
            event:{start:null, end:null, title:null, customer:null},
            eventClickModal:false,
            eventCustomerModal:false,
            eventEditModal: false,
        })
    };

    onChangeSelection(value) {
        this.setState({
            event:{
                ...this.state.event,
                customer:{
                    id:value,
                    ...this.validateSelect(value)
                }
            }
        })
    }

    handleStartDateChange=(event)=>{
        this.setState({
            event:{
                ...this.state.event,
                start: event.toDate()
            }
        })

    }

    handleEndDateChange=(event)=>{
        this.setState({
            event:{
                ...this.state.event,
                end: event.toDate()
            }
        })
    }

    handleTitleChange=(event)=>{
        this.setState({
            event:{
                ...this.state.event,
                title: {
                    text:event.target.value,
                    ...this.validateInput(event.target.value)
                },

            }
        })
    }

    handleSubmit=(event)=>{
        const startDate= new Date(this.state.event?.start);
        const endDate = new Date(this.state.event?.end)
        const eventData = {
            start: startDate.toLocaleString('lt-LT'),
            end: endDate.toLocaleString('lt-LT'),
            customer: parseInt(this.state.event?.customer?.id),
            title:this.state.event?.title?.text,
        }
        // console.log(new Date(this.state.startDate).toLocaleString('lt-LT'))
        // console.log(changeTimeZone(this.state.endDate,"UTC"))
        // console.log(changeTimeZone(this.state.endDate,"Europe/Vilnius"))
        createEvent(eventData)
            .then(response => {
                this.props.history.push("/calendar");
                this.handleCancel();
                this.loadEvents();
            }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
            } else {
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            }
        });
    }

    handleUpdateSubmit=()=>{
        const startDate= new Date(this.state.event?.start);
        const endDate = new Date(this.state.event?.end)
        const eventData = {
            id:this.state.event?.id,
            start: startDate.toLocaleString('lt-LT'),
            end: endDate.toLocaleString('lt-LT'),
            customer: parseInt(this.state.event?.customer?.id),
            title:this.state.event?.title?.text,
        }
        // console.log(new Date(this.state.startDate).toLocaleString('lt-LT'))
        // console.log(changeTimeZone(this.state.endDate,"UTC"))
        // console.log(changeTimeZone(this.state.endDate,"Europe/Vilnius"))
        updateEvent(eventData)
            .then(response => {
                this.props.history.push("/calendar");
                this.handleCancel();
                this.loadEvents();
            }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
            } else {
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            }
        });
    }

    handleEventClick=(event)=>{
        this.validateIfEventHasVisitation(event.id);
        this.setState({
            eventClickModal:true,
            event: event,
        })
    }

    handleCustomerModalOpen=()=>{
        this.setState({
            eventCustomerModal:true,
        })
    }

    handleDeleteEvent=()=>{

        const eventData={
            id:this.state.event?.id
        }

        deleteEvent(eventData)
            .then(response => {
                this.props.history.push("/calendar");
                this.handleCancel();
                this.loadEvents();
                notification.success({
                    message: 'ediMiss App',
                    description: response.message
                })
            }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
            } else {
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            }
        });
    }

    handleCreateVisitationFromEvent=()=>{

        const eventData={
            id:this.state.event?.id
        }

        createVisitationFromEvent(eventData)
            .then(response => {
                this.props.history.push("/calendar");
                this.handleCancel();
                this.loadEvents();
                if(response.success){
                    notification.success({
                        message: 'ediMiss App',
                        description: response.message
                    })
                }else{
                    notification.error({
                        message: 'ediMiss App',
                        description: response.message
                    })
                }
            }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
            } else {
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            }
        });
    }

    validateIfEventHasVisitation=(eventId)=>{
        existVisitationByEvent(eventId).then(r => {
                this.setState({
                    existVisitationByEvent: r,
                })
            }
        )
    }

    handleOpenEditModal=()=>{
        this.setState({
            event:{
                ...this.state.event,
                title:{
                    text:this.state.event?.title,
                    ...this.validateInput(this.state.event?.title)
                }
            },
            eventEditModal:true,
        })
    }

    validateInput=(title)=>{
        if(title.length === 0) {
            return {
                validateStatus: 'error',
                errorMsg: 'Please enter title!'
            }
        }else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }
    validateSelect=(selection)=>{
        if(selection === '' || selection === null) {
            return {
                validateStatus: 'error',
                errorMsg: 'Please select customer!'
            }
        }else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }

    isFormInvalid() {
        if(this.state.event?.title?.validateStatus !== 'success') {
            return true;
        }

        if(this.state.event?.customer?.validateStatus !== 'success'){
            return true;
        }
    }

    isUpdateFormInvalid() {
        if(this.state.event?.title?.validateStatus !== 'success') {
            return true;
        }
    }

    confirm=(e)=> {
        this.handleDeleteEvent();
    }

    cancel=(e)=> {
        this.handleCancel()
    }


    render() {

        const format = 'HH:mm';

        const children = [];
        this.state.customers?.forEach(customer => {children.push(<Option key={customer.id}><div style={{display:"inline-block"}}>{customer.fullName} <p style={{fontSize:"10px"}}>{customer.phone}</p></div> <div style={{float:"right",display:"inline-block"}}><Image
            width={40}
            preview={false}
            style={{borderRadius:"20px",}}
            src={customer.customerPicture?.url!=null?customer.customerPicture.url:"https://basanaviciausprogimnazija.lt/wp-content/uploads/2018/04/no-user-image.gif"}
        /></div></Option>);})

        const events = this.state.events;



        return (
            <div >
                <Spin spinning={this.state.isLoading}>
                <DnDCalendar
                    defaultDate={moment().toDate()}
                    defaultView="agenda"
                    events={events}
                    localizer={localizer}
                    onEventDrop={this.onEventDrop}
                    onEventResize={this.onEventResize}
                    resizable
                    min={new Date(0, 0, 0, 6, 0, 0)}
                    max={new Date(0, 0, 0, 22, 0, 0)}
                    timeslots={6}
                    step={10}
                    style={{ height: "80vh", marginTop:"20px" }}
                    onSelectSlot={(slotInfo) => {
                        this.openEventCreateModal(slotInfo)
                    }}
                    onSelectEvent={this.handleEventClick}
                    selectable
                    popup={true}
                    // messages={{
                    //     next: "Kita",
                    //     previous: "Ankstesnė",
                    //     today: "Šiandiena",
                    //     month: "Mėnuo",
                    //     week: "Savaitė",
                    //     day: "Diena"
                    // }}
                    components={{
                        event: EventComponent
                    }}

                />
                <Modal title="Create Event" visible={this.state.eventModal}  onCancel={this.handleCancel} footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>,
                    <Button onClick={this.handleSubmit} type="primary" htmlType="submit" disabled={this.isFormInvalid()}>
                        Add
                    </Button>,
                ]}>

                    <Form onSubmit={this.handleSubmit} className="create-poll-form"  >
                        <FormItem  className="poll-form-row" >
                            <div style={{display:"inline-block"}}>
                                <b style={{marginRight:"10px"}}>start time</b>

                                <TimePicker minuteStep={5} value={moment(this.state.event?.start, format)} format={format} onChange={this.handleStartDateChange} placement={"bottomRight"} inputReadOnly={true}/>
                            </div>
                           <div style={{display:"inline-block", marginTop:"10px"}}>
                               <b style={{marginRight:"10px", marginLeft:"10px"}}>end time</b>
                               <TimePicker minuteStep={5} value={moment(this.state.event?.end, format)} format={format} onChange={this.handleEndDateChange} placement={"bottomRight"} inputReadOnly={true}/>
                           </div>
                        </FormItem>
                        <FormItem  className="poll-form-row" validateStatus={this.state.event?.title?.validateStatus}
                                   help={this.state.event?.title?.errorMsg}>
                            <Input
                                placeholder="Title"
                                style = {{ fontSize: '16px' }}
                                size="large"
                                name = "title"
                                value = {this.state.event?.title?.text === null?undefined:this.state.event?.title?.text}
                                //value = {this.state.title}
                                className={this.state.event?.title?.validateStatus !== 'success' ? "optional-choice": null}
                                onChange = {this.handleTitleChange} />
                            {this.state.event?.title?.validateStatus !=='success' ? (
                                <ExclamationCircleOutlined className={"dynamic-delete-button"} style={{color:'red'}}/>
                            ): null}
                        </FormItem>
                        <FormItem  className="poll-form-row" validateStatus={this.state.event?.customer?.validateStatus}
                                   help={this.state.event?.customer?.errorMsg}>
                            <Select
                                size="large"
                                showSearch
                                name={"customer"}
                                placeholder="Please select customer"
                                onChange={this.onChangeSelection}
                                className={this.state.event?.customer?.validateStatus !== 'success' ? "optional-choice-select": null}
                                value={this.state.event?.customer?.id==null ? undefined :this.state.event?.customer?.id}
                                // value={this.state.customer}
                                filterOption={(input, option) => {
                                    const values = option.props.children[0].props.children[0] + (option.props.children[0].props.children[2].props.children!=null? (" "+option.props.children[0].props.children[2].props.children): "");
                                    return values.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                }
                            >
                                {children}
                            </Select>
                            {this.state.event?.customer?.validateStatus !=='success' ? (
                                <ExclamationCircleOutlined className={"dynamic-delete-button"} style={{color:'red'}}/>
                            ): null}

                        </FormItem>

                    </Form>
                </Modal>
                    <Modal title="Actions" visible={this.state.eventClickModal}  onCancel={this.handleCancel} footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                    ]}>
                        <div>

                            <Button onClick={this.handleCustomerModalOpen}  className={"buttons"}>View Customer Card</Button>

                            <Button onClick={this.handleOpenEditModal} className={"buttons"}>Edit Event</Button>
                            <Popconfirm
                                title="Are you sure to delete this event?"
                                onConfirm={this.confirm}
                                onCancel={this.cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                            <Button onClick={()=>{}} className={"buttons"}>Delete Event</Button>
                            </Popconfirm>

                            {this.state.existVisitationByEvent ? null: (
                                <Button onClick={this.handleCreateVisitationFromEvent}  className={"buttons"}>Create Visitation</Button>
                            )}
                        </div>

                    </Modal>

                <Modal title="Customer" visible={this.state.eventCustomerModal}  onCancel={this.handleCancel} footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>,
                ]}>
                    <div>
                        <CustomerCard customer={this.state.event?.customer?.id}/>
                    </div>

                </Modal>

                    <Modal  visible={this.state.eventEditModal}  onCancel={this.handleCancel} footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button onClick={this.handleUpdateSubmit} type="primary" htmlType="submit" disabled={this.isUpdateFormInvalid()}>
                            Update
                        </Button>,
                    ]}>
                        <div>
                            <Form className="create-poll-form" >
                                <FormItem  className="poll-form-row">
                                    <div style={{display:"inline-block"}}>
                                        <b style={{marginRight:"10px"}}>start time</b>
                                        <TimePicker minuteStep={10} value={moment(this.state.event?.start, format)} format={format} onChange={this.handleStartDateChange} placement={"bottomRight"} inputReadOnly={true}/>
                                    </div>
                                    <div style={{display:"inline-block", marginTop:"10px"}}>
                                        <b style={{marginRight:"10px", marginLeft:"10px"}}>end time</b>
                                        <TimePicker minuteStep={10} value={moment(this.state.event?.end, format)} format={format} onChange={this.handleEndDateChange} placement={"bottomRight"} inputReadOnly={true}/>
                                    </div>
                                </FormItem>
                                <FormItem  className="poll-form-row" >
                                    <Input
                                        placeholder="Title"
                                        style = {{ fontSize: '16px' }}
                                        size="large"
                                        name={"title"}
                                        value = {this.state.event.title== null?undefined:this.state.event.title.text!=null?this.state.event.title.text:this.state.event.title}
                                        onChange = {this.handleTitleChange} />
                                </FormItem>
                                <FormItem  className="poll-form-row" >
                                    <Select
                                        size="large"
                                        showSearch
                                        name={"customer"}
                                        placeholder="Please select customer"
                                        disabled={true}
                                        value={this.state.event?.customer==null ? undefined :this.state.event?.customer?.fullName}
                                        // value={this.state.customer}
                                        filterOption={(input, option) => {
                                            const values = option.props.children[0].props.children[0] + (option.props.children[0].props.children[2].props.children!=null? (" "+option.props.children[0].props.children[2].props.children): "");
                                            return values.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        }
                                    >
                                        {children}
                                    </Select>

                                </FormItem>
                            </Form>
                        </div>

                    </Modal>
                </Spin>
            </div>
        );
    }

}


function EventComponent(event){
    return(
        <div style={{width:"100%"}}>
            <span> <strong> {event.title} </strong> </span>
            <span style={{float:"right"}}> <strong> {event.event.customer?.fullName} </strong> </span>
        </div>

    );
}
export default withRouter(CalendarSch);
