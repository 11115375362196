import React, {Component} from "react";
import {Button, Form, Image, Input, notification, Select, Spin} from "antd";
import FormItem from "antd/es/form/FormItem";
import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';
import {API_BASE_URL, API_BASE_URL_PHOTO, MAX_CHOICES} from "../constants";
import {
    createCustomer,
    deleteCustomerPicture,
    getAllIllnessesSelect, updateCustomer
} from "../util/APIUtils";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import Upload from "rc-upload";
const { Option } = Select;

class NewCustomer extends Component{
    constructor(props) {
        super(props);
        this.state={
            customer: props.customer!=null ? props.customer:{},
            customerCustomFields:props.customer!=null ? props.customer.customerCustomFields:[],
            illnesses: props.customer!=null ? props.customer.illnesses:null,
            illnessesList:null,
            isLoading:false,
            customerPicture: props.customer!=null ? props.customer.customerPicture:null,
        }

        this.loadIllnesses = this.loadIllnesses.bind(this);
        this.handleIllnessChange = this.handleIllnessChange.bind(this);
        this.handleCustomerCustomFieldCommentChange = this.handleCustomerCustomFieldCommentChange.bind(this);
        this.handleCustomerCustomFieldNameChange = this.handleCustomerCustomFieldNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleFullNameChange = this.handleFullNameChange.bind(this);
        this.removeCustomerCustomField = this.removeCustomerCustomField.bind(this);
        this.addCustomerCustomField = this.addCustomerCustomField.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        this.loadIllnesses();
    }
    loadIllnesses(){
        let promise;
        promise = getAllIllnessesSelect();

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    illnessesList: response,
                    isLoading: false
                });
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    addCustomerCustomField(event) {
        const customerCustomFields = this.state.customerCustomFields.slice();
        this.setState({
            customerCustomFields: customerCustomFields.concat([{
                name: '',
                comment: '',
            }])
        });
    }

    removeCustomerCustomField(customerCustomFieldNumber) {
        const customerCustomFields = this.state.customerCustomFields.slice();
        this.setState({
            customerCustomFields: [...customerCustomFields.slice(0, customerCustomFieldNumber), ...customerCustomFields.slice(customerCustomFieldNumber+1)]
        });
    }

    handleFullNameChange(event) {
        const value = event.target.value;
        this.setState({
            customer:{
                ...this.state.customer,
                fullName:value,
            }
        });
    }
    handlePhoneChange(event) {
        const value = event.target.value;
        this.setState({
            customer:{
                ...this.state.customer,
                phoneNumber:value,
            }
        });
    }

    handleEmailChange(event) {
        const value = event.target.value;
        this.setState({
            customer:{
                ...this.state.customer,
                email:value,
            }
        });
    }

    handleCustomerCustomFieldNameChange(event, index) {
        const customerCustomFields = this.state.customerCustomFields.slice();
        const value = event.target.value;

        customerCustomFields[index] = {
            ...customerCustomFields[index],
            name: value,

        }

        this.setState({
            customerCustomFields: customerCustomFields
        });
    }
    handleCustomerCustomFieldCommentChange(event, index) {
        const customerCustomFields = this.state.customerCustomFields.slice();
        const value = event.target.value;

        customerCustomFields[index] = {
            ...customerCustomFields[index],
            comment: value,
        }

        this.setState({
            customerCustomFields: customerCustomFields
        });
    }

    handleIllnessChange(value) {

        const illnesses = value;
        const illnessesList =[];
        illnesses.map(item=>{
            const illness1 = this.state.illnessesList?.find(illness=>illness.id === parseInt(item))
            illnessesList.push(illness1);
        });
        this.setState({
            illnesses:illnessesList,
        });
    }

    handleSubmit() {
        const customerData = {
            ...this.state.customer,
            illnesses:this.state.illnesses,
            customerCustomFields: this.state.customerCustomFields.map(customerCustomField => {
                return {name: customerCustomField.name, comment: customerCustomField.comment}
            }),
            customerPictureId:this.state.customerPicture?.id,
        };

        if(this.props.customer!=null){
            updateCustomer(customerData)
                .then(response => {
                    this.props.handleCancel()
                    this.props.reloadCustomer(customerData.id)
                }).catch(error => {
                if (error.status === 401) {
                    this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
                } else {
                    notification.error({
                        message: 'ediMiss App',
                        description: error.message || 'Sorry! Something went wrong. Please try again!'
                    });
                }
            });
        }else {
            createCustomer(customerData)
                .then(response => {
                    this.props.history.push("/customer/list");
                }).catch(error => {
                if (error.status === 401) {
                    this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
                } else {
                    notification.error({
                        message: 'ediMiss App',
                        description: error.message || 'Sorry! Something went wrong. Please try again!'
                    });
                }
            });
        }
    }

    onSuccessHandle=(event)=>{
        this.setState({
            customerPicture:event
        })
    }

    imageComponent=(item)=>{
        return(
            <div style={{border:"1px solid #efefef", padding:"10px", marginTop:"20px"}}>
                    <div className={"imageBox"}>
                        <Image
                            width={100}
                            src={item.url}
                        />
                        <div style={{float:"right", fontSize:"35px", color:"#a40000", cursor:"pointer"}}>
                            <CloseOutlined onClick={()=>this.handleFileRemove(item.id)}/>
                        </div>
                    </div>
            </div>
        );
    }

    handleFileRemove=(fileId)=>{
        const fileData = {
            id: parseInt(fileId)
        };
        deleteCustomerPicture(fileData)
            .then(response => {
                        this.setState({
                            customerPicture:null,
                        })
                        notification.success({
                            message: 'ediMiss App',
                            description: response.message,
                        });

            }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
            } else {
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            }
        });
    }

    render(){

        const customerCustomFieldViews = [];
        this.state.customerCustomFields?.forEach((customerCustomField, index) => {
            customerCustomFieldViews.push(<CustomerCustomField key={index} customerCustomField={customerCustomField} customerCustomFieldNumber={index} removeCustomerCustomField={this.removeCustomerCustomField}  handleCustomerCustomFieldNameChange={this.handleCustomerCustomFieldNameChange} handleCustomerCustomFieldCommentChange={this.handleCustomerCustomFieldCommentChange}/>);
        });

        const children = [];
        this.state.illnessesList?.forEach(illness => {children.push(<Option key={illness.id}>{illness.name}</Option>);})



        return(
            <Spin spinning={this.state.isLoading}>
                <div className="new-poll-container">
                    {this.props.customer!=null?null:(
                        <h1 className="page-title">Create New Customer</h1>
                    )}
                    <div className="new-poll-content">
                        <Form onFinish={this.handleSubmit} className="create-poll-form" initialValues={this.props.customer!=null?this.props.customer:null}>
                            <FormItem className="poll-form-row">
                                {this.state.customerPicture!=null ? this.imageComponent(this.state.customerPicture):(
                                    <Upload
                                        action={API_BASE_URL_PHOTO+'/file/upload/customerPicture'}
                                        onSuccess={this.onSuccessHandle}
                                        multiple={true}
                                    ><Button className={"uploadButton"}>Upload Images</Button></Upload>
                                )}
                            </FormItem>
                            <FormItem  className="poll-form-row" name={"fullName"} rules={[{ required: true, message: 'Please enter customers full name!' }]}>
                                <Input
                                    placeholder="Enter Full Name"
                                    name={"fullName"}
                                    style = {{ fontSize: '16px' }}
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    size="large"
                                    value = {this.state.customer?.fullName}
                                    onChange = {this.handleFullNameChange} />
                            </FormItem>
                            <FormItem  className="poll-form-row">
                                <Input
                                    placeholder="Enter Phone Number"
                                    style = {{ fontSize: '16px' }}
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    size="large"
                                    type={"tel"}
                                    value = {this.state.customer?.phoneNumber}
                                    onChange = {this.handlePhoneChange} />
                            </FormItem>
                            <FormItem  className="poll-form-row">
                                <Input
                                    placeholder="Enter Email"
                                    style = {{ fontSize: '16px' }}
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    size="large"
                                    type={"email"}
                                    value = {this.state.customer?.email}
                                    onChange = {this.handleEmailChange} />
                            </FormItem>
                            <FormItem  className="poll-form-row">
                                <Select
                                    mode="multiple"
                                    size="large"
                                    placeholder="Select Illnesses"
                                    onChange={this.handleIllnessChange}
                                    value={this.state.illnesses?.map(item=>({key:item.id, label:item.name}))}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {children}
                                </Select>
                            </FormItem>

                            {customerCustomFieldViews}
                            <FormItem className="poll-form-row">
                                <Button type="dashed" onClick={this.addCustomerCustomField} disabled={this.state.customerCustomFields.length === MAX_CHOICES}>
                                    <PlusOutlined /> Add a custom customer field
                                </Button>
                            </FormItem>
                            <FormItem className="poll-form-row">
                                <Button type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className="create-poll-form-button">{this.props.customer!=null?("Update Customer"):("Create Customer")}</Button>
                            </FormItem>

                        </Form>
                    </div>
                </div>
            </Spin>
        )
    }
}

function CustomerCustomField(props) {
    return (
        <FormItem  className="poll-form-row" style={{display:"inline"}}>
            <Input
                placeholder = {'Field Name ' + (props.customerCustomFieldNumber + 1)}
                size="large"
                style={{display:"inline", width:"30%", marginRight:"10px"}}
                value={props.customerCustomField.name}
                // className={ props.productCustomFieldNumber > 0 ? "optional-choice": null}
                onChange={(event) => props.handleCustomerCustomFieldNameChange(event, props.customerCustomFieldNumber)} />
            <Input
                placeholder = {'Field Comment ' + (props.customerCustomFieldNumber + 1)}
                size="large"
                style={{display:"inline", width:"50%"}}
                value={props.customerCustomField.comment}
                className={ props.customerCustomFieldNumber >= 0 ? "optional-choice": null}
                onChange={(event) => props.handleCustomerCustomFieldCommentChange(event, props.customerCustomFieldNumber)} />
            <CloseOutlined style={{display:"inline", width:"20%"}} className="dynamic-delete-button" onClick={() => props.removeCustomerCustomField(props.customerCustomFieldNumber)}/>


        </FormItem>
    );
}
export default NewCustomer;
